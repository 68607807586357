
import { IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent } from 'vue';

import AppMenu from '@/components/layout/AppMenu.vue';

export default defineComponent({
  name: 'App',
  components: {
    IonRouterOutlet,
    IonSplitPane,
    AppMenu,
  },
});
