
import { defineComponent, ref, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  IonMenu,
  IonMenuToggle,
  IonBadge,
  IonIcon,
  IonLabel,
  IonItem,
  IonContent,
  IonList,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/vue";
import {
  logOutOutline,
  logOutSharp,
  homeOutline,
  personCircleOutline,
  personCircleSharp,
  pricetagOutline,
  pricetagSharp,
  cardOutline,
  walletOutline,
  cardSharp,
} from "ionicons/icons";

import { auth, merchants } from "@/store";

export default defineComponent({
  components: {
    IonMenu,
    IonMenuToggle,
    IonBadge,
    IonIcon,
    IonLabel,
    IonItem,
    IonContent,
    IonList,
    IonAvatar,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
  },
  setup() {
    const selectedIndex = ref(0);
    const route = useRoute();
    const router = useRouter();

    const { merchant } = toRefs(merchants.state);

    const logoutUser = () => {
      auth.clearUser();
      merchants.clearMerchantProfile();
      router.push("/login");
    };

    onMounted(async () => {
      await merchants.retrieveMechantProfile();
    });

    const appPages = ref([
      {
        title: "Overview",
        url: "/app",
        iosIcon: homeOutline,
        mdIcon: homeOutline,
      },
      {
        title: "Profile",
        url: "/app/profile",
        iosIcon: personCircleOutline,
        mdIcon: personCircleSharp,
      },
      {
        title: "Pricing",
        url: "/app/pricing",
        iosIcon: pricetagOutline,
        mdIcon: pricetagSharp,
      },
      {
        title: "Payment Settings",
        url: "/app/payment",
        iosIcon: cardOutline,
        mdIcon: cardSharp,
      },
      {
        title: "Earnings",
        url: "/app/earnings",
        iosIcon: walletOutline,
        mdIcon: walletOutline,
      },
    ]);

    return {
      selectedIndex,
      appPages,
      logOutSharp,
      logOutOutline,
      homeOutline,
      walletOutline,
      merchant,
      isSelected: (url: string) => url === route.path,
      logoutUser,
    };
  },
});
